import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles';
import {
  DispatchStatus, DispatchDragNDropTypes, DispatchOperationType, DispatchOperationTypeName,
} from '~/utils';
import PF2MDispatchIconMaker from '../PF2MDispatchIconMaker';
import PF2MTooltip from '../PF2MTooltip';
import { useTranslation } from 'react-i18next';
import { formatDurationAsTime } from '~/utils/moment';

const TRUCK = 2;

const statusClasses = {
  [DispatchStatus.OP]: 'greenLabel',
  [DispatchStatus.PO]: 'yellowLabel',
  [DispatchStatus.MA]: 'redLabel',
  [DispatchStatus.IN]: 'yellowLabel',
  [DispatchOperationType.LOAD_TRAFFIC]: 'grayLabel',
  [DispatchOperationType.LOAD_QUEUE]: 'brownLabel',
  [DispatchOperationType.LOAD]: 'limeLabel',
  [DispatchOperationType.UNLOAD_TRAFFIC]: 'blackLabel',
  [DispatchOperationType.UNLOAD_QUEUE]: 'brownLabel',
  [DispatchOperationType.UNLOAD]: 'greenLabel',
  [DispatchOperationType.LOAD_MANEUVER]: 'blueLabel',
  [DispatchOperationType.UNLOAD_MANEUVER]: 'blueLabel',
  [DispatchOperationType.EMPTY_STOP]: 'yellowLabel',
  [DispatchOperationType.RELEASED_EQUIPMENT]: 'yellowLabel',
};

const statusIcons = (status) => {
  const icons = {
    [DispatchStatus.OP]: { main: 'truckFull', warning: 'none' },
    [DispatchStatus.PO]: { main: 'truckEmpty', warning: 'pause' },
    [DispatchStatus.MA]: { main: 'truckEmpty', warning: 'maintenance' },
    [DispatchStatus.IN]: { main: 'truckEmpty', warning: 'pause' },
    [DispatchOperationType.LOAD_TRAFFIC]: { main: 'truckEmpty', warning: 'none' },
    [DispatchOperationType.LOAD_QUEUE]: { main: 'truckEmpty', warning: 'time' },
    [DispatchOperationType.LOAD]: { main: 'truckEmpty', warning: 'none' },
    [DispatchOperationType.UNLOAD_TRAFFIC]: { main: 'truckFull', warning: 'none' },
    [DispatchOperationType.UNLOAD_QUEUE]: { main: 'truckFull', warning: 'time' },
    [DispatchOperationType.UNLOAD]: { main: 'truckUnloading', warning: 'none', isBackground: true },
    [DispatchOperationType.LOAD_MANEUVER]: { main: 'truckEmpty', warning: 'steering' },
    [DispatchOperationType.UNLOAD_MANEUVER]: { main: 'truckFull', warning: 'steering' },
    [DispatchOperationType.EMPTY_STOP]: { main: 'truckEmpty', warning: 'pause' },
    [DispatchOperationType.RELEASED_EQUIPMENT]: { main: 'truckFull', warning: 'pause' },
  };
  if (icons[status] === undefined) {
    return { main: 'truckEmpty', warning: 'none' };
  }
  return icons[status];
};

const PF2MEquipIcon = ({
  item, itemType, customStyles, classes, reverse,
  useOperationType, extraInfo,
  onContextMenu,
  footer,
  header,
  height = 85,
  tooltipData,
  ...rest
}) => {
  const [, drag, preview] = useDrag({
    item: { type: itemType, obj: item },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const { t: translate } = useTranslation();

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const statusProp = useOperationType ? 'operation_type' : 'status';

  const tooltipText = tooltipData ? (
    <div className={styles.equipmentTooltip}>
      <div>
        <strong>{tooltipData.equip_name}</strong>
      </div>
      <div>
        <strong>
          {`${translate('common:Origin')} / ${translate('common:OriginPoint')}: `}
        </strong>
        <span>{tooltipData.current_origin_point_name}</span>
      </div>
      <div className={classes.equipmentTooltipOperators}>
        <strong style={{ marginRight: '4px' }}>
          {`${translate('common:Operator')}:`}
        </strong>
        <span className={classes.titleTooltipOperators}>
          {tooltipData.operators_name}
        </span>
      </div>
      <div>
        <strong>
          {`${translate('common:Code')}: `}
        </strong>
        <span>{tooltipData.codes_name}</span>
      </div>
      <div>
        <strong>
          {`${translate('common:Material')}: `}
        </strong>
        <span>{tooltipData.current_material_name}</span>
      </div>
      <div>
        <strong>
          {`${translate('common:Duration')}: `}
        </strong>
        <span>{formatDurationAsTime(tooltipData.durations)}</span>
      </div>
      {tooltipData.equip_type_id === 2 && (
        <div>
          <strong>
            {`${translate('common:CurrentCycleStep')}: `}
          </strong>
          <span>{DispatchOperationTypeName[tooltipData.operation_type]}</span>
        </div>
      )}
    </div>
  ) : <strong>{`${translate('common:DataUnavailable')}`}</strong>;

  const iconReverse = item.operation_type === 4 ? !reverse : reverse;

  const getDisplayValidationType = (equipItem) => {
    const validateOperationTypeLoading = equipItem.operation_type === 4
      && equipItem.current_element_point_id !== equipItem.current_origin_point_id;

    const validateOperationTypeDumping = equipItem.operation_type === 7
      && equipItem.current_element_point_id !== equipItem.current_destination_point_id;

    if (validateOperationTypeLoading) {
      return 'flex';
    } if (validateOperationTypeDumping) {
      return 'flex';
    }
    return 'none';
  };

  const styleTypeWarning = getDisplayValidationType(item);

  return (
    <div
      onContextMenu={event => onContextMenu && onContextMenu(event, item)}
      style={{
        width: 70,
        height,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        cursor: 'pointer',
        ...customStyles,
      }}
      role="cell"
      ref={drag}
      {...rest}
    >
      {header !== undefined ? header : (
        item.equip_type_id === TRUCK && (
          <div
            className={classes.labelTypeSelector}
            style={{
              display: 'flex', justifyContent: 'center', width: '100%', height: 20, color: '#647886',
            }}
          >
            {String(extraInfo) !== 'null' ? String(extraInfo).slice(0, 9) : '  '}
          </div>
        )
      )}
      <PF2MTooltip title={tooltipData?.equip_name ? tooltipText : ''}>
        <div style={{ position: 'relative', top: 0, left: 0 }}>
          <PF2MDispatchIconMaker
            iconName={
              {
                2: statusIcons(item[statusProp]).main,
                1: 'excavator',
                15: 'loader',
                4: 'perforator',
              }[item.equip_type_id] || 'excavator'
            }
            settings={{
              iconSize: 70,
              isBackground: statusIcons(item[statusProp]).isBackground,
              reverse: iconReverse,
              alt: item.equip_name,
              warnings: (() => {
                const firstWarning = statusIcons(item[statusProp]).warning !== styleTypeWarning
                  ? {
                    icon: statusIcons(item[statusProp]).warning,
                    position: iconReverse ? 'top-right' : 'top-left',
                    alt: 'Atenção',
                  }
                  : null;
                const secondWarning = firstWarning?.icon !== 'none' && styleTypeWarning === 'flex'
                  ? {
                    icon: 'attentionAnomaly',
                    position: iconReverse ? 'top-left' : 'top-right',
                    alt: 'Atenção',
                  }
                  : null;
                return [firstWarning, secondWarning].filter(Boolean);
              })(),
            }}
          />
        </div>
      </PF2MTooltip>
      {footer !== undefined ? footer : (
        <div
          className={cx(classes.labelTypeSelector,
            classes[statusClasses[item[statusProp]]]
              ? classes[statusClasses[item[statusProp]]]
              : classes.greenLabel)}
          style={{ width: '100%', textAlign: 'center' }}
        >
          {(item.equip_name || '').slice(0, 9)}
        </div>
      )}
    </div>
  );
};

PF2MEquipIcon.propTypes = {
  classes: PropTypes.object,
  customStyles: PropTypes.object,
  itemType: PropTypes.string,
  item: PropTypes.object,
  onClick: PropTypes.func,
  status: PropTypes.string,
  reverse: PropTypes.bool,
  useOperationType: PropTypes.bool,
  extraInfo: PropTypes.string,
  onContextMenu: PropTypes.func,
  header: PropTypes.node,
  footer: PropTypes.node,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  tooltipData: PropTypes.array,
};

PF2MEquipIcon.defaultProps = {
  classes: {},
  customStyles: {},
  status: DispatchStatus.OP,
  itemType: DispatchDragNDropTypes.TRUCK,
  item: {},
  onClick: () => null,
  reverse: false,
  useOperationType: false,
  extraInfo: '',
  onContextMenu: null,
  header: undefined,
  footer: undefined,
  height: 85,
  tooltipData: {},
};

export default withStyles(styles)(PF2MEquipIcon);
