import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MDataTable from '~/components/PF2MDataTable';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getEquipmentTypes,
  getLoads,
  setLoadsValues,
  updateLoads,
  resetLoads,
} from '~/store/manager/actions';


const LoadTab = ({
  classes,
  loads,
  getLoads: getAll,
  getEquipmentTypes: loadEquipmentTypes,
  equipmentTypes,
  setLoadsValues: updateItem,
  updateLoads: updateEditedLoad,
  resetLoads: reset,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [equipmentType, setEquipmentType] = useState(0);
  const { t: translate } = useTranslation();

  const transportAndLoadEquips = [1, 2, 15, 29, 32];

  useEffect(() => {
    async function fetchData() {
      await loadEquipmentTypes();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [
    dataLoaded,
    loadEquipmentTypes,
  ]);

  useEffect(() => {
    async function fetchData() {
      await getAll(equipmentType);
    }
    if (equipmentType > 0) {
      fetchData();
    }
  }, [getAll, equipmentType]);

  const columns = [
    {
      editable: 'never',
      title: translate('common:EquipmentGroup'),
      field: 'equip_group_name',
      // customType: 'filterLookup',
      // lookup: equipmentGroups.map(item => ({ ...item, id:item.name})),
      required: true,
    },
    {
      editable: 'never',
      field: 'equip_name',
      title: translate('common:Equipment'),
      // customType: 'filterLookup',
      // lookup: equipments.map(item => ({ ...item, id:item.name})),
      required: true,
    },
    {
      editable: 'never',
      title: translate('common:Material'),
      field: 'material_name',
      // customType: 'filterLookup',
      // lookup: materials.map(item => ({ ...item, id: item.name })),
      required: true,
    },
    {
      editable: 'never',
      field: 'operation_type_name',
      title: translate('common:OperationType'),
    },
    {
      editable: 'always',
      field: 'value',
      title: translate('common:AverageLoad'),
    },
  ];

  const renderItems = (items = []) => [{ value: 0, label: translate('common:Select') }]
    .concat(...items
      .map(e => ({ value: e.id, label: e.name })));

  return (
    <div className={classes.tabContainer}>
      <div style={{
        position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%',
      }}
      >
        <span className={classes.labelTypeSelector}>
          {`${translate('common:EquipmentType')}:`}
        </span>
        <PF2MSearchSelectOutlined
          value={equipmentType}
          onChange={(e) => {
            setEquipmentType(e.target.value);
          }}
          className={classes.typeSelector}
        >
          {renderItems(equipmentTypes.filter(et => transportAndLoadEquips.includes(et.id)))}
        </PF2MSearchSelectOutlined>
      </div>

      <PF2MDataTable
        title={translate('common:AverageLoad')}
        options={{
          showTitle: true,
          search: false,
          selection: true,
          filtering: true,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100],
          hideDelete: true,
          hideAdd: true,
          maxBodyHeight: 'calc(100vh - 350px)',
          minBodyHeight: 'calc(100vh - 350px)',
          columnId: 'id_link',
        }}
        onChange={(newData) => {
          newData.forEach((row) => {
            updateItem(row);
          });
        }}
        onDiscard={() => reset()}
        onApply={() => { updateEditedLoad(loads, equipmentType); }}
        columns={columns}
        data={loads?.length > 0 ? loads : []}
      />
    </div>
  );
};

LoadTab.propTypes = {
  classes: PropTypes.object.isRequired,
  loads: PropTypes.array,
  equipmentTypes: PropTypes.array,
  getEquipmentTypes: PropTypes.func,
  getLoads: PropTypes.func,
  setLoadsValues: PropTypes.func,
  updateLoads: PropTypes.func,
  resetLoads: PropTypes.func,
};

LoadTab.defaultProps = {
  loads: [],
  equipmentTypes: [],
  getEquipmentTypes: null,
  getLoads: null,
  setLoadsValues: null,
  updateLoads: null,
  resetLoads: null,
};

const mapStateToProps = state => ({
  equipmentTypes: state.manager.equipmentTypes,
  loads: state.manager.loads,
});

const mapDispatchToProps = {
  getEquipmentTypes,
  getLoads,
  setLoadsValues,
  updateLoads,
  resetLoads,
};

// Ver funcionamento do updateLoads para Apply
// Lembrar de enviar apenas os editados

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoadTab));
